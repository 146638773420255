export function convertMS(milliseconds) {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    // var day, hour, minute, seconds;
    // seconds = Math.floor(milliseconds / 1000);
    // minute = Math.floor(seconds / 60);
    // seconds = seconds % 60;
    // hour = Math.floor(minute / 60);
    // minute = minute % 60;
    // day = Math.floor(hour / 24);
    // hour = hour % 24;
    return [days, hours % 24, minutes % 60, seconds % 60];
}

export const pad = (x) => String(x).padStart(2, '0');
export const isLeapYear = (year) => (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
export const incrementDate = (dateString) => modifyDate(dateString, 1);
export const decrementDate = (dateString) => modifyDate(dateString, -1);
export const reformatDate = (d) => d.split('/').reverse().join("/");

export function convertYYToYYYY(dateString) {
    const [day, month, year] = dateString.split('/');
    return `${pad(day)}/${pad(month)}/20${year}`;
}

function modifyDate(dateString, days) {
    const [day, month, year] = dateString.split("/").map(Number);
    const date = new Date(year, month - 1, day);
    date.setDate(date.getDate() + days);
    return `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}`
}


export function addSleepLengths(x, y) {
    var [xHour, xMin] = x.split(":").map(Number);
    var [yHour, yMin] = y.split(":").map(Number);
    let minutes = xMin + yMin;
    let hours = xHour + yHour + Math.floor(minutes / 60);
    minutes %= 60;
    return `${pad(hours)}:${pad(minutes)}`;
}

export const roundTime = (hours, mins) => hours + (Math.round((mins / 60) * 2) / 2);


export function addHoursToDateTime(date, hours, offset) {
    let hrs = parseFloat(hours);
    let [offsetHours, period] = offset;
    offsetHours = parseInt(offsetHours);

    if (period == "PM" && offsetHours != 12) offsetHours += 12;
    if (period == "AM" && offsetHours == 12) offsetHours += 12;

    hrs -= offsetHours - 11;
    const nextDay = hrs >= 24;
    if (hrs < 0) hrs += 24;
    if (nextDay) date = incrementDate(date);
    return [date, hrs.toFixed(1), hours - hrs, offsetHours, period];
}

export function generateTodayDate(x) {
    const d = new Date();
    const unformattedDate = `${pad(d.getDate())}/${pad(d.getMonth() + 1)}/${d.getFullYear()}`;
    const roundedTime = parseFloat(roundTime(d.getHours(), d.getMinutes())).toFixed(1);
    const offset = x != "10:00:00 AM" ? [x.split(":")[0], x.includes("PM") ? "PM" : "AM"] : ["10", "AM"];
    const newHours = addHoursToDateTime(unformattedDate, roundedTime, offset)
    return [reformatDate(newHours[0]), newHours[1], `${pad(d.getHours() - newHours[2])}:${pad(d.getMinutes())}`, newHours[3], newHours[4]];
}

export function genUntilTime(slot1, day1, slot2, day2) {
    const parseSlot = (slot) => `${slot}`.includes('.') ? `${slot}` : `${slot}.0`;
    const res = [parseSlot(slot1)];
    let tmp = parseFloat(slot1);
    if (day1 == day2) {
        for (let i = 0; i < 99; i++) {
            if (tmp >= slot2) break;
            tmp += 0.5;
            res.push(tmp.toFixed(1))
        }
        return { day1: res }
    } else {
        let res2 = ['0.0'], tmp2 = 0;
        for (let i = 0; i < 99; i++) {
            if (tmp >= 23.5 && tmp2 >= slot2) break;
            if (tmp < 23.5) {
                tmp += 0.5;
                res.push(tmp.toFixed(1))
            } else {
                tmp2 += 0.5;
                res2.push(tmp2.toFixed(1))
            }
        }
        return { day1: res, day2: res2 }
    }
}