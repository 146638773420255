import React, { useState, useEffect } from "react";

export default function Lyrics() {
    const [trackLyrics, setTrackLyrics] = useState([]);
    const [loadBool, setLoadBool] = useState(false);
    const [lyricsColour, setLyricsColour] = useState("#2c2c2c");
    const [availableLanguages, setAvailableLanguages] = useState([]);
    const [availableTracks, setAvailableTracks] = useState([]);
    const [selectedTrack, setSelectedTrack] = useState("");
    const [selectedLanguage, setSelectedLanguage] = useState("");

    useEffect(() => {
        if (!loadBool) {
            setLoadBool(true);
            getAllTracks();
        }
    }, [loadBool]);

    useEffect(() => {
        if (availableTracks.length > 0 && selectedTrack === "") {
            const defaultTrack = availableTracks[0];
            setSelectedTrack(defaultTrack.songId);
            setLyrics(defaultTrack.songId);
        }
    }, [availableTracks]);

    useEffect(() => {
        if (availableLanguages.length > 0 && selectedLanguage === "") {
            setSelectedLanguage(availableLanguages[0]);
        }
    }, [availableLanguages]);

    function getUserTimezone() {
        const utcDate = new Date(Date.UTC(2023, 0, 1));
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const formattedTime = utcDate.toLocaleTimeString('en-US', { timeZone: userTimezone });
        return { timezone: userTimezone, formattedTime };
    }

    function expandLyrics(lyrics) {
        var newLyrics = [];
        for (let i = 0; i < lyrics.length; i++) {
            if (typeof (lyrics[i].timestamp) == "object") {
                var timestamps = lyrics[i].timestamp;
                for (let j = 0; j < timestamps.length; j++) {
                    var temp = { ...lyrics[i] };
                    temp.timestamp = timestamps[j];
                    newLyrics.push(temp);
                }
            } else newLyrics.push(lyrics[i]);
        }
        return newLyrics;
    }

    function setLyrics(trackid) {
        var data = availableTracks.find(x => x.songId == trackid);
        if (data.langs.length > 0) setAvailableLanguages(data.langs);
        else setAvailableLanguages([]);
        var fullLyrics = expandLyrics(data.lyrics);
        if (data.langs.length > 0 && availableLanguages.length == 0) {
            setTrackLyrics(fullLyrics.map(obj => fixLyrics(obj[data.langs[0]])));
        } else {
            if (availableLanguages.length > 0) {
                if (!data.langs.includes(selectedLanguage)) {
                    setSelectedLanguage(availableLanguages[0]);
                    setTrackLyrics(fullLyrics.map(obj => fixLyrics(obj[availableLanguages[0]])));
                } else if (selectedLanguage != "") setTrackLyrics(fullLyrics.map(obj => fixLyrics(obj[selectedLanguage])));
                else setTrackLyrics(fullLyrics.map(obj => fixLyrics(obj[availableLanguages[0]])));
            } else setTrackLyrics(fullLyrics.map(obj => fixLyrics(obj.text)));
        }
        setLyricsColour(data.colour);
    }

    function fixLyrics(content) {
        return content?.length > 0 ? content : "\u3000";
    }

    function updateLyrics(trackid, selLang) {
        var data = availableTracks.find(x => x.songId == trackid);
        var fullLyrics = expandLyrics(data.lyrics);
        setTrackLyrics(fullLyrics.map(obj => fixLyrics(obj[selLang])));
        setLyricsColour(data.colour);
    }

    function getAllTracks() {
        fetch(`https://api.xn--ddk7b.com/api/lyrics/all`, { headers: { 'Content-Type': 'application/json' } }).then(r => r.json()).then(data => {
            if (data.message == "success") {
                setAvailableTracks(data.data.lyrics.sort((a, b) => a.songId - b.songId))
            } else window.alert('An error occurred.');
        })
    }

    const handleLanguageChange = (event) => {
        const lang = event.target.value;
        setSelectedLanguage(lang);
        updateLyrics(selectedTrack, lang)
    }

    const handleTrackChange = (event) => {
        const track = event.target.value;
        setLyrics(track)
        setSelectedTrack(track);
    }
    var timezone = getUserTimezone();
    if (timezone.timezone.includes("Manila")) {
        window.alert("You are restricted from accessing this page.");
        var root = document.getElementById("root");
        root.remove();
        return;
    }

    window.addEventListener('resize', function () {
        const lyricsContainer = document.getElementById('lyricsContainer');
        const trackChange = document.getElementById('trackChange');
        const languageChange = document.getElementById('languageChange');

        if (lyricsContainer) {
            const scaleFactor = Math.min(window.innerWidth / 1920, window.innerHeight / 1080);
            const fontSize = scaleFactor * 16;
            lyricsContainer.style.fontSize = `${fontSize}px`;

            trackChange.style.fontSize = window.innerWidth < 1000 ? `${fontSize * 4}px` : `20px`;
            languageChange.style.fontSize = window.innerWidth < 1000 ? `${fontSize * 4}px` : `20px`;
        }
    });

    window.dispatchEvent(new Event('resize'));

    return (<div className="player" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 auto', fontSize: '24px', maxWidth: '1200px' }}>
        <div className="selectors" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '20px' }}>
            <div className="language-selector">
                <select id="languageChange" value={selectedLanguage} onChange={handleLanguageChange} className="dropdown" style={{ width: "200px" }}>
                    {availableLanguages.map((locale) => (
                        <option key={locale} value={locale}>
                            {locale.charAt(0).toUpperCase() + locale.slice(1)}
                        </option>
                    ))}
                </select>
            </div>
            <div className="track-selector">
                <select id="trackChange" value={selectedTrack} onChange={handleTrackChange} className="dropdown">
                    {availableTracks.map((track) => (
                        <option key={track.songId} value={track.songId}>
                            {track.name}
                        </option>
                    ))}
                </select>
            </div>
        </div>
        <div id="lyricsContainer" className="lyrics-container" style={{ backgroundColor: lyricsColour || '#2b2b2b', padding: '20px 50px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)' }}>
            <div className="lyrics">
                {trackLyrics.map((line, index) => (
                    <div key={index} className="lyric-line">
                        {line}
                        <br />
                    </div>
                ))}
            </div>
        </div>
    </div>)
}