import React, { useState } from "react";
import { pad, convertMS, reformatDate, addSleepLengths, roundTime, generateTodayDate, genUntilTime, convertYYToYYYY, incrementDate } from "../Methods";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from "moment";

export default function Sleep() {
    const [loadBool, setLoadBool] = useState(false);
    const [syncTime, setSyncTime] = useState("");
    const [localTime, setLocalTime] = useState("");
    const [n24timeZone, setn24timeZone] = useState("");
    const [UserTimeZone, setUserTimeZone] = useState("");
    const [fetchData, setFetchData] = useState();
    const [averageSleepChart, setAverageSleepChart] = useState(<div></div>);

    function getUserTimezone() {
        const utcDate = new Date(Date.UTC(2023, 0, 1));
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const formattedTime = utcDate.toLocaleTimeString('en-US', { timeZone: userTimezone });
        return { timezone: userTimezone, formattedTime };
    }

    if (!loadBool) {
        setLoadBool(true);
        ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)
        fetch(`https://api.xn--ddk7b.com/api/v1/sleep/all`).then(r => r.json()).then(fdata => {
            setFetchData(fdata);
            var data = fdata;

            function timeToDecimal(x) {
                const [hours, minutes] = x.split(":").map(Number);
                return `${pad(hours)}.${pad(Math.round((minutes / 60) * 100))}`
            }

            function integerToTime(val) {
                const hours = Math.floor(val);
                const minutes = Math.round((val % 1) * 60);
                return minutes == 60 ? `${pad(hours + 1)}:00` : `${pad(hours)}:${pad(minutes)}`;
            }

            function generateOffset(x, y) {
                if (prevDayNight.length == 0) {
                    prevDayNight = [...x];
                    prevDayWake = [...y];
                    dailyIncrementX.push(0);
                    dailyIncrementY.push(0);
                    return;
                }
                calculateOffset(prevDayNight, x, prevDayWake, y);
                prevDayNight = [...x];
                prevDayWake = [...y];
            }

            function calculateOffset(x1, x2, y1, y2) {
                const toMinutes = ([h, m]) => h * 60 + m;
                const nightDiff = toMinutes(x2) - toMinutes(x1);
                const dayDiff = toMinutes(y2) - toMinutes(y1);
                dailyIncrementX.push(nightDiff);
                dailyIncrementY.push(dayDiff);
            }

            function logSleepTimes(slot1, day1, slot2, day2, slplng) {
                var d1 = dayMap.find(o => o.date == day1);
                if (!d1) return;
                if (day1 !== day2) {
                    var d2 = dayMap.find(o => o.date == day2);
                    d2.sleep_length = d2.sleep_length != "-" ? addSleepLengths(d2.sleep_length, slplng) : slplng;
                    const { day1: day1Slots, day2: day2Slots } = genUntilTime(slot1, day1, slot2, day2);
                    day1Slots.forEach(r => d1.hours[0][r] = 1);
                    day2Slots.forEach(r => d2.hours[0][r] = 1);
                } else {
                    const day1Slots = genUntilTime(slot1, day1, slot2, day2).day1;
                    d1.sleep_length = d1.sleep_length != "-" ? addSleepLengths(d1.sleep_length, slplng) : slplng;
                    day1Slots.forEach(r => d1.hours[0][r] = 1)
                }
            }

            function generateRawSleepLength(x) {
                if (x == "-") return 0;
                const [hours, minutes] = x.split(":").map(Number);
                return hours + (minutes / 60);
            }

            function getSleepLength(o, n) {
                generateOffset(o, n);
                const toDecimal = ([h, m]) => h + (m / 60);
                const oDecimal = toDecimal(o);
                const nDecimal = toDecimal(n) < oDecimal ? toDecimal(n) + 24 : toDecimal(n);
                const sleepLength = nDecimal - oDecimal;
                sleepTimeMap.push(sleepLength);
                const hours = Math.floor(sleepLength);
                const minutes = Math.ceil((sleepLength % 1) * 60);
                return `${pad(hours)}:${pad(minutes)}`;
            }

            function createTableHeader(headerRow) {
                var dateCell = headerRow.insertCell();
                dateCell.innerText = 'Date';
                dateCell.classList.add("headerCell");

                for (let i = 0; i < 48; i++) {
                    var hourCell = headerRow.insertCell();
                    hourCell.innerHTML = parseFloat(i / 2).toString();
                    hourCell.classList.add("headerCell");
                }

                var sleepLengthCell = headerRow.insertCell()
                sleepLengthCell.innerText = "Length";
                sleepLengthCell.classList.add("headerCell")
            }

            function initialiseHourSlots() {
                const slots = {};
                for (let i = 0; i < 24; i += 0.5) {
                    slots[i.toFixed(1)] = 0;
                }
                return slots;
            }

            var dayMap = [];
            var sleepTimeMap = [];
            var prevDayWake = [];
            var prevDayNight = [];
            var dailyIncrementX = [];
            var dailyIncrementY = [];

            const currCell = generateTodayDate(getUserTimezone().formattedTime);
            const sortedData = [...data.day, ...data.night].sort((a, b) => a.timestamp - b.timestamp);

            const table = document.getElementById('sleep-log');
            const headerRow = table.insertRow();
            createTableHeader(headerRow);

            let currDate = convertYYToYYYY(`${sortedData[0].day}/${sortedData[0].month}/${sortedData[0].year}`);
            let endDate = convertYYToYYYY(`${sortedData[sortedData.length - 1].day}/${sortedData[sortedData.length - 1].month}/${sortedData[sortedData.length - 1].year}`)
            if (currCell[0] != endDate) endDate = incrementDate(endDate);

            while (currDate != incrementDate(endDate)) {
                dayMap.push({
                    sleep_length: "-",
                    offsetX: "-",
                    offsetY: "-",
                    pattern: dayMap.length % 2 == 0,
                    date: reformatDate(currDate),
                    hours: [initialiseHourSlots()]
                });
                currDate = incrementDate(currDate);
            }

            var prevSleepDay = "";
            var prevSleep = "";
            var prevSleepRaw = [];
            var isNight = true;

            sortedData.forEach((entry) => {
                const hr = parseInt(entry.hour);
                const min = parseInt(entry.minute);
                const currentDate = reformatDate(convertYYToYYYY(`${entry.day}/${entry.month}/${entry.year}`));

                if (isNight) {
                    prevSleepDay = currentDate;
                    prevSleep = roundTime(hr, min);
                    prevSleepRaw = [hr, min];
                } else {
                    if (!prevSleepDay) return;
                    const sleepLength = getSleepLength(prevSleepRaw, [hr, min])
                    logSleepTimes(prevSleep, prevSleepDay, roundTime(hr, min), currentDate, sleepLength)
                }
                isNight = !isNight;
            });


            function calculateMovingAverage(data, windowSize) {
                return data.map((_, i) => {
                    const slice = data.slice(Math.max(i - windowSize + 1, 0), i + 1);
                    return slice.reduce((sum, value) => sum + value, 0) / slice.length;
                });
            }

            function calculateDeviation(data, windowSize) {
                return data.map((_, i) => {
                    const slice = data.slice(Math.max(i - windowSize + 1, 0), i + 1).filter(val => val !== 0);
                    const mean = slice.reduce((sum, value) => sum + value, 0) / slice.length;
                    const variance = slice.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / slice.length;
                    return Math.sqrt(variance);
                });
            }

            function calculateOffsetMap(increments, indexes, windowSize) {
                return increments.map((_, i) => {
                    const slice = increments.slice(Math.max(i - windowSize + 1, 0), i + 1).filter(x => x > -800);
                    const avg = slice.reduce((sum, val) => sum + val, 0) / slice.length / 60;
                    return indexes.includes(i) ? avg : avg;
                });
            }

            function generateChartOptions() {
                return {
                    scales: {
                        x: {
                            type: 'category',
                            title: { display: true, text: 'Date' },
                            grid: { color: 'rgba(255, 255, 255, 0.1)' },
                            ticks: { color: 'rgba(255,255,255,0.5)' }
                        },
                        y: {
                            beginAtZero: true,
                            title: { display: true, text: 'Hours' },
                            grid: { color: 'rgba(255, 255, 255, 0.1)' },
                            ticks: { maxTicksLimit: 24, color: 'rgba(255,255,255,0.5)' }
                        }
                    },
                    plugins: {
                        legend: {
                            labels: {
                                color: 'white', filter: (item) => {
                                    return item.text != "0";
                                }
                            },
                            display: true,
                            position: 'top'
                        },
                        tooltip: {
                            callbacks: {
                                label: (item) => {
                                    if (item.dataset.label == "0") return '';
                                    return `${item.dataset.label}: ${item.formattedValue}`
                                }
                            }
                        }
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    aspectRatio: 4,
                    elements: { line: { tension: 0.4 } },
                    layout: { padding: { left: 10, right: 10, top: 10, bottom: 10 } },
                };
            }

            function generateChartData(labels, hours, rawAverageMap, deviationMap, tempX, tempY) {
                return {
                    labels,
                    datasets: [
                        { label: 'Hours Slept', data: hours, borderColor: 'rgba(173, 216, 230, 1)', backgroundColor: 'rgba(173, 216, 230, 0.2)', borderWidth: 1, lineTension: 0, fill: false },
                        { label: 'Avg Sleep 3d', data: rawAverageMap, borderColor: 'rgba(255, 0, 0, 1)', borderWidth: -1, lineTension: 0, fill: false },
                        { label: 'Deviation 5d', data: deviationMap, borderColor: 'rgba(0, 42, 255, 1)', borderWidth: 1, lineTension: 0, fill: false },
                        { label: 'Sleep-Offset 3d', data: tempX, borderColor: 'rgba(227, 227, 89, 1)', borderWidth: -1, lineTension: 0, fill: false },
                        { label: 'Wake-Offset 3d', data: tempY, borderColor: 'rgba(89, 227, 126, 1)', borderWidth: -1, lineTension: 0, fill: false },
                        { label: '0', data: Array(hours.length).fill().map(() => 0), borderColor: `rgba(150,150,150,0.5)`, borderWidth: -1, fill: false }
                    ]
                };
            }

            const dates = dayMap.map(data => data.date);
            const hours = dayMap.map(data => generateRawSleepLength(data.sleep_length));

            const rawAverageMap = calculateMovingAverage(hours, 3);
            const deviationMap = calculateDeviation(hours, 5);

            var indexes = []

            for (var i = 0; i < dayMap.length - 1; i++) {
                if (dayMap[i].sleep_length == "-") indexes.push(i);
            }

            const tempX = calculateOffsetMap(dailyIncrementX, indexes, 3);
            const tempY = calculateOffsetMap(dailyIncrementY, indexes, 3);

            const latestSleepData = fdata.night[fdata.night.length - 1];
            const latestSleepTime = `${latestSleepData.hour}:${latestSleepData.minute}`;
            const latestSleepDecimal = timeToDecimal(latestSleepTime);
            const averageSleepOffset = tempX[tempX.length - 1];
            const nextSleepDecimal = parseFloat(latestSleepDecimal) + averageSleepOffset;
            let nextSleepHour = Math.floor(nextSleepDecimal) % 24;
            let nextSleepMinute = Math.round((nextSleepDecimal % 1) * 60);

            if (nextSleepMinute === 60) {
                nextSleepMinute = 0;
                nextSleepHour += 1;
            }

            nextSleepHour = nextSleepHour % 24;
            const nextSleepTime = `${pad(nextSleepHour)}:${pad(nextSleepMinute)}`;
            const finalSleepHour = nextSleepHour;
            const finalSleepMinute = nextSleepMinute;
            let [latestDay, latestMonth, latestYear] = incrementDate(reformatDate(`20${latestSleepData.year}/${latestSleepData.month}/${latestSleepData.day}`)).split('/');

            if (nextSleepDecimal >= 24) {
                const nextSleepDate = incrementDate(`${pad(latestDay)}/${pad(latestMonth)}/${pad(latestYear)}`);
                [latestYear, latestMonth, latestDay] = reformatDate(nextSleepDate).split('/');
            }
            const nextSleepDay = `${pad(latestYear)}/${pad(latestMonth)}/${pad(latestDay)}`;

            const chartOptions = generateChartOptions();
            const chartData = generateChartData(dates, hours, rawAverageMap, deviationMap, tempX, tempY, [Array(hours.length).fill().map(() => 0)]);
            setAverageSleepChart(<Line options={chartOptions} data={chartData} />);

            var odd = true;
            var tempDate = `/${pad(fdata.lastRecordedMonth)}/${pad(fdata.lastRecordedDate)}`;
            var tempMet = fdata.lastRecordedTime == "";
            const timeValues = ["0.0", "0.5", "1.0", "1.5", "2.0", "2.5", "3.0", "3.5", "4.0", "4.5", "5.0", "5.5", "6.0", "6.5", "7.0", "7.5", "8.0", "8.5", "9.0", "9.5", "10.0", "10.5", "11.0", "11.5", "12.0", "12.5", "13.0", "13.5", "14.0", "14.5", "15.0", "15.5", "16.0", "16.5", "17.0", "17.5", "18.0", "18.5", "19.0", "19.5", "20.0", "20.5", "21.0", "21.5", "22.0", "22.5", "23.0", "23.5"]
            dayMap.forEach(({ date, hours, pattern, sleep_length }) => {
                const row = table.insertRow();
                const dateCell = row.insertCell();
                dateCell.innerText = date;
                dateCell.classList.add(odd ? "dateCell" : "dateCell2");
                timeValues.forEach(r => {
                    const cell = row.insertCell()
                    cell.style.color = "white";
                    const isCurrent = currCell[0] == date && currCell[1] == r;
                    const isSleep = hours[0][r] == 1;
                    const isTempSleep = !tempMet && date.includes(tempDate) && roundTime(...fdata.lastRecordedTime.split(":").map(Number)) <= r;
                    if (isSleep) cell.classList.add("sleepCell");
                    else if (isCurrent) {
                        cell.classList.add("currentCell");
                        cell.innerHTML = currCell[2];
                        tempMet = true;
                    } else if (isTempSleep) cell.classList.add("tempSleepCell");
                    else if (nextSleepDay == date && roundTime(finalSleepHour, finalSleepMinute) == r) {
                        cell.classList.add("estCell");
                        cell.innerHTML = nextSleepTime;
                    } else cell.classList.add(pattern ? "wakeCell" : "wakeCell2")
                })
                const sleepCell = row.insertCell();
                sleepCell.innerHTML = sleep_length;
                if (sleep_length == "-") sleepCell.style.backgroundColor = 'rgb(20,20,20)';
                sleepCell.classList.add(odd ? "slplng2" : "slplng")
                odd = !odd;
            });

            var statsTable = document.getElementById('sleep-stats');
            statsTable.classList.add("statsTable")
            var statsHeader = statsTable.insertRow();
            var headerValues = ['Avg. Sleep Length (30d)', 'Avg. Wake Length (30d)']
            headerValues.map(x => {
                var xValue = statsHeader.insertCell();
                xValue.innerText = x;
                xValue.classList.add("headerCell");
            })
            var rowValues = [integerToTime(hours.slice(-30).reduce((acc, val) => acc + val, 0) / 30), integerToTime(24 - hours.slice(-30).reduce((acc, val) => acc + val, 0) / 30)]
            var xRow = statsTable.insertRow();
            rowValues.map(x => {
                var xCell = xRow.insertCell();
                xCell.classList.add("wakeCell2")
                xCell.innerHTML = x;
            })
            const footerRow = table.insertRow();
            var dateCell = footerRow.insertCell();
            dateCell.innerText = 'Date';
            dateCell.classList.add("headerCell");

            for (let i = 0; i < 48; i++) {
                var hourCell = footerRow.insertCell();
                hourCell.innerHTML = parseFloat(i / 2).toString();
                hourCell.classList.add("headerCell");
            }
            var sleepLengthCell = footerRow.insertCell()
            sleepLengthCell.innerText = "Length";
            sleepLengthCell.classList.add("headerCell")
        })

    }

    window.addEventListener('resize', function () {
        const scaledTableContainer = document.getElementById('scaledTableContainer');
        const mainDiv = document.getElementById("mainDiv");
        const divContainer = document.getElementById("divContainer");
        const hoursChartContainer = document.getElementById("hoursChartContainer");
        const sleepInfo = document.getElementById("sleepInfo");

        if (scaledTableContainer) {
            const scaleFactor = Math.min(window.innerWidth / 1920, window.innerHeight / 1080);
            const fontSize = scaleFactor * 16;
            scaledTableContainer.style.fontSize = `${fontSize}px`;
            if (divContainer) divContainer.style.fontSize = window.innerWidth < 1000 ? `${fontSize * 3}px` : `20px`;
            if (mainDiv) {
                const boundingRect = scaledTableContainer.getBoundingClientRect();
                mainDiv.style.height = `${boundingRect.height}px`;
            }
            sleepInfo.style.fontSize = window.innerWidth < 1000 ? `${fontSize * 3.5}px` : "16px";
            if (hoursChartContainer) hoursChartContainer.style.width = window.innerWidth < 1000 ? "100vw" : "95vw";
        }
    });

    window.dispatchEvent(new Event('resize'));

    function formatUserTimezone(x) {
        var tzo = new Date().getTimezoneOffset();
        return [tzo > 0 ? `GMT-${tzo / 60}` : `GMT+${Math.abs(tzo / 60)}`, `${pad(new Date().getHours())}:${pad(new Date().getMinutes())}`]
    }

    function customRound(number) {
        const floorValue = Math.floor(number);
        return number % 1 >= 0.6 ? floorValue + 1 : floorValue;
    }

    function calculateTimeDifference(time1, time2) {
        var [h1, m1] = time1.split(':').map(Number);
        var [h2, m2] = time2.split(':').map(Number);
        var isNegative = true;
        var total1 = h1 * 60 + m1;
        var total2 = h2 * 60 + m2;
        if ((total1 - total2) > 0) isNegative = false;
        let diff = Math.abs(total1 - total2);
        return { diff, isNegative };
    }

    function sliceGMT(x) {
        if (x == "GMT+0") return x;
        if (x.split("")[4] == 0) {
            return x.substr(0, 4) + x.substr(4 + 1);
        }
        return x;
    }

    function adjustTimezone(timezone, minutes, isNegative) {
        const hours = customRound(minutes / 60);
        const matches = timezone.match(/GMT([+\-]\d+)/);
        const gmtOffset = parseInt(matches[1]);
        let newOffset;
        newOffset = isNegative ? gmtOffset - hours : gmtOffset + hours;
        newOffset = ((newOffset + 12) % 24 + 24) % 24 - 12;
        const newTimezone = `GMT${newOffset >= 0 ? '+' : ''}${newOffset}`;
        return newTimezone;
    }

    const timezones = { 'GMT-12': ['USA (Islands)'], 'GMT-11': ['American Samoa'], 'GMT-10': ['French Polynesia'], 'GMT-9': ['USA (Alaska)'], 'GMT-8': ['USA (Pacific Time)'], 'GMT-7': ['USA (Mountain Time)'], 'GMT-6': ['USA (Central Time)'], 'GMT-5': ['USA (Eastern Time)'], 'GMT-4': ['Bolivia'], 'GMT-3': ['Argentina'], 'GMT-2': ['Brazil'], 'GMT-1': ['Cape Verde'], 'GMT+0': ['Iceland'], 'GMT+1': ['Nigeria'], 'GMT+2': ['Zimbabwe'], 'GMT+3': ['Iraq'], 'GMT+4': ['United Arab Emirates'], 'GMT+5': ['Pakistan'], 'GMT+6': ['Bangladesh'], 'GMT+7': ['Vietnam'], 'GMT+8': ['Philippines'], 'GMT+9': ['Japan'], 'GMT+10': ['Australia'], 'GMT+11': ['Vanuatu'], 'GMT+12': ['Fiji'] };
    var fontChange = window.innerWidth < 1000 ? "20px" : "38px";

    function setTimezone(x, y) {
        if (n24timeZone == "") {
            setn24timeZone(x)
            setUserTimeZone(y)
        }
    }

    if (fetchData) {
        function clock() {
            var d = fetchData.day;
            var rcd = d[d.length - 1];
            var d1 = new Date();
            var lh = d1.getHours(), lm = d1.getMinutes(), ls = d1.getSeconds();
            var [day, hour, minute, seconds] = convertMS(moment(d1.getTime() - parseInt(rcd.timestamp)));//.add(8, "hours").format('x'));
            setSyncTime(`${pad(hour)}:${pad(minute)}:${pad(seconds)}`);
            setLocalTime(`${pad(lh)}:${pad(lm)}:${pad(ls)}`);

            if (syncTime != "") {
                const userTimezone = formatUserTimezone();
                var timeDiff = calculateTimeDifference(syncTime.slice(0, -3), userTimezone[1])
                var synctimezone = adjustTimezone(userTimezone[0], timeDiff.diff, timeDiff.isNegative);
                setTimezone(timezones[synctimezone][0], sliceGMT(synctimezone));
            }
        }
        setTimeout(clock, 1000);
    }

    return (
        <div className="mainDiv" id="mainDiv">
            <div className="toplevel">
                <a id="oldRedirect" href="/old-sleep">Click for old sleep data</a>
                <div style={{ margin: "20px" }} id="sleepInfo">
                    <p>Non-24-Hour Sleep-Wake Disorder, also known as Non-24, is a circadian rhythm disorder where the body's internal clock runs longer than 24 hours. As a result, sleep and wake times gradually shift later each day. The data below allows me to understand my current sleeping patterns, and visually represent my sleeping schedule.</p>

                    <p>Here is how you can effectively interpret the charted data:<br />- The hours slept is the amount of hours I've slept each day. Entries marked as '0' happen every time my schedule shifts fully (~2 weeks stable).<br />
                        - The average hours slept is the average hours I've slept for every solar day in the past 3 days. This is not parallel with my circadian day. Generally 7.4 hours of average sleep is equal to 8 hours of sleep per circadian day.<br />
                        - The deviation is for the mean deviation of sleep length for the past 5 days. Less than 1.2 = somewhat normal. Less than 0.8 = stable. Greater than 2 = altered schedule or sleep deprived. Between 1.2 and 2 = poor sleep quality/sleep disturbance.<br />
                        - The sleep offset is the average increase in hours per day for the times I sleep.<br />
                        - The wake offset is the average increase in hours per day for the times I wake up.</p>
                </div>

                <div className="divcontainer" id="divContainer">
                    <div className="centered-content">
                        <p>Synced Clock<br /><span style={{ fontSize: fontChange }}>{syncTime}</span></p>
                    </div>
                    <div className="centered-content">
                        <p>Local Clock<br /><span style={{ fontSize: fontChange }}>{localTime}</span></p>
                    </div>
                    <div className="centered-content">
                        <p>N24 Timezone<br /><span style={{ fontSize: fontChange }}>{n24timeZone}</span> <span style={{ fontSize: "12px" }}>{`${UserTimeZone}`}</span></p>
                    </div>
                </div>
            </div>
            <div id="hoursChartContainer" style={{ margin: '0 auto', marginBottom: "20px", height: '40vh', width: '95vw' }}>
                {averageSleepChart}
            </div>
            <div className="secondlevel" id="scaledTableContainer">
                <table className="norm" id="sleep-log" />
                <table id="sleep-stats" />
            </div>
        </div>
    )

}